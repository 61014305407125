import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { footerElement } from "../styles/footer.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const CommitteeMember = ({ memberName, deputy, inactive }) => {
  let titleFirst = "Dr. med.";
  let displayName = "";
  let titleSecond = "MD";
  let position = "";
  let mailAddress = "";
  let displayImage = null;
  let committeePosition = "Foundation Committee Board Member";
  let isDeputy = deputy !== undefined && deputy;
  let isInactive = inactive !== undefined && inactive;

  switch (memberName) {
    case "tanjahaenger":
      displayName = "Tanja Hänger";
      position = "Head Case Researcher, Liposuction Specialist";
      mailAddress = "haenger";
      displayImage = (
        <StaticImage src="../images/board/drtanjahaenger.jpg" alt="" />
      );
      break;
    case "harryvanderwal":
      displayName = "Harry van der Wal";
      position = "Male Cosmetics Specialist";
      mailAddress = "vanderwal";
      displayImage = (
        <StaticImage src="../images/board/drharryvanderwal.jpg" alt="" />
      );
      break;
    case "caroldiaz":
      displayName = "Carol Diaz";
      titleSecond = "MD FACS";
      position = "Shape Specialist";
      mailAddress = "diaz";
      displayImage = (
        <StaticImage src="../images/board/drcaroldiaz.jpg" alt="" />
      );
      break;
    case "henritauziat":
      displayName = "Henri Tauziat";
      titleSecond = "MD M.Sc. M.A.";
      position = "Breast Specialist, Speech Therapist";
      mailAddress = "tauziat";
      displayImage = (
        <StaticImage src="../images/board/drhenritauziat.jpg" alt="" />
      );
      break;
    case "justuspeters":
      displayName = "Justus Peters";
      titleSecond = "MD FACS M.Sc.";
      position = "Breast Specialist";
      mailAddress = "peters";
      displayImage = (
        <StaticImage src="../images/board/drjustuspeters.jpg" alt="" />
      );
      break;
    case "leticiadumas":
      displayName = "Leticia Dumas";
      position = "Lead Case Researcher, Breast Specialist";
      mailAddress = "dumas";
      displayImage = (
        <StaticImage src="../images/board/drleticiadumas.jpg" alt="" />
      );
      break;
    case "olgakoenigstein":
      displayName = "Olga Königstein";
      titleSecond = "MD M.Sc.";
      position = "Shape Specialist";
      mailAddress = "koenigstein";
      displayImage = (
        <StaticImage src="../images/board/drolgakoenigstein.jpg" alt="" />
      );
      break;
    case "romanwagener":
      displayName = "Roman Wagener";
      titleSecond = "MD M.Sc.";
      position = "Senior Lead Case Researcher, Male Cosmetics Specialist";
      mailAddress = "wagener";
      displayImage = (
        <StaticImage src="../images/board/drromanwagener.jpg" alt="" />
      );
      break;
    case "stefaniekarmann":
      displayName = "Stefanie Karmann";
      titleFirst = "Dr. med. Dr. med. dent.";
      position = "Dental Specialist";
      mailAddress = "karmann";
      displayImage = (
        <StaticImage src="../images/board/drstefaniekarmann.jpg" alt="" />
      );
      break;
    case "thomasabasco":
      displayName = "Thomas Abasco";
      titleSecond = "MD FACS M.Sc.";
      position = "Shape Specialist";
      mailAddress = "abasco";
      displayImage = (
        <StaticImage src="../images/board/drthomasabasco.jpg" alt="" />
      );
      break;
      case "caraanderson":
      displayName = "Cara Anderson";
      titleSecond = "MD FACS M.Sc.";
      position = "Case Researcher, Implants Specialist";
      mailAddress = "anderson";
      displayImage = (
        <StaticImage src="../images/board/drcaraanderson.jpg" alt="" />
      );
      break;
  }

  let mailLine = <p className="small">E-Mail: {mailAddress}@...</p>;
  let positionLine = <p className="small text-muted">{position}</p>;
  if (isDeputy) {
    committeePosition = "Foundation Committee Board Deputy Member";
  }

  if (isInactive) {
    committeePosition = "Former or inactive Board Member";
    mailLine = null;
    positionLine = null;
  }


  return (
    <Col md={4} sm={6} lg={3} xs={6} className="mb-4">
      {displayImage}
      <p className="mt-2 mb-2">
        <span className="small text-muted">{titleFirst}</span>
        <br />
        <span className="fw-bold">{displayName}</span>
        <br />
        <span className="small text-muted">{titleSecond}</span>
      </p>
      {mailLine}
      {positionLine}
      <p className="small mb-2">{committeePosition}</p>

    </Col>
  );
};

export default CommitteeMember;
