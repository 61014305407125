import * as React from "react";
import Layout from "../components/layout";
import { Col, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import CommitteeMember from "../components/committeemember";

const IndexPage = () => {
  return (
    <Layout>
      <Row className="mb-3">
        <Col>
          <StaticImage
            src="../images/img_start.jpg"
            alt=""
            placeholder="blurred"
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h1 className="mb-3">
            <span className="small text-muted">Welcome at the</span> <br />
            Wegener-Hänger-Abasco Foundation for Beauty Treatments
          </h1>
          <p>
            We are a non-profit foundation, founded by a global association of
            top plastic and cosmetic surgeons. We provide world-class beauty
            medicine treatment for women and men - modern surgery procedures and
            unmatched before and after treatment consultancy.
          </p>
          <p>
            All members donate 10 % of their yearly revenues for our
            organizations budget, with which we are able to offer a{" "}
            <Link to="/non-profit">variety of Non-Profit-Services</Link>. This
            allows us, to provide almost all services related to beauty medicine
            completely free of charge in many cases.
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h2 className="mb-3">Our History and Mission</h2>
          <p>
            We founded the MM Aesthetics Foundation in Switzerland on April 15,
            1994. Founding members were Dr. med. Roman Wagener, Dr. med. Tanja
            Hänger and Dr. med. Thomas Abasco MD FACS.
          </p>
          <p>
            On this day, we defined our mission statements, which are still
            valid today:
          </p>
          <ul>
            <li>
              We provide top-class cosmetic and plastic treatments for all our
              patients everywhere in the world.
            </li>
            <li>
              We provide these treatments for free, in case the patients are
              severely affected by their appearance and cannot pay for a
              treatment because of personal or economic reasons.
            </li>
            <li>
              Everybody has the chance to apply for a free treatment and every
              application is evaluated by the same fair criteria.
            </li>
          </ul>

          <p>Beginning July 1st, 2022 the foundation has been rebranded to
            Wegener-Hänger-Abasco Foundation for Beauty Treatments. Effective
            on the same date, the sponsoring structures have been renewed.
          </p>

          <p>
            Today, our foundation consists of our committee board with 5
            full-time members, 5 deputy members, plus 38 plastic surgeon
            partners who provide our services in their local clinics and
            facilities.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h2 className="mb-3">Our Foundation Committee Board</h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <CommitteeMember memberName="tanjahaenger" />
        <CommitteeMember memberName="romanwagener" />
        <CommitteeMember memberName="thomasabasco" />
        <CommitteeMember memberName="caroldiaz" />
        <CommitteeMember memberName="leticiadumas" />

        <CommitteeMember memberName="henritauziat" deputy={true} />
        <CommitteeMember memberName="stefaniekarmann" deputy={true} />
        <CommitteeMember memberName="harryvanderwal" deputy={true} />
        <CommitteeMember memberName="olgakoenigstein" deputy={true} />
        <CommitteeMember memberName="caraanderson" deputy={true} />

        <CommitteeMember memberName="justuspeters" inactive={true}/>
      </Row>
    </Layout>
  );
};

export default IndexPage;
